<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex flex-column align-items-center justify-content-center px-5">
          <b-img
            class="w-75"
            fluid
            :src="sideImg"
            alt="Login"
          />
          <h1 class="mt-3 text-center">Coming Soon</h1>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to Tru-Fence Supply
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginValidation"
            #default="{ handleSubmit }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link
                    tabindex="-1"
                    @click="navigateToForgotPassword()"
                  >
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <spinner-button
                :loading="submitting"
                variant="primary"
                block
                type="submit"
              >
                Sign In
              </spinner-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import jwt from '@/auth/jwt/useJwt'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import { $themeConfig } from '@themeConfig'
import store from '@/store'
import User from '@/objects/User'
import RoleTypes from '@/enums/RoleTypes'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    ValidationProvider,
    ValidationObserver,
    SpinnerButton,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      userEmail: '',
      password: '',
      sideImg: $themeConfig.app.appLogoWithName,
      // validation rulesimport store from '@/store/index'
      required,
      email,
      submitting: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup() {
    const userEmail = ''
    const password = ''

    return {
      userEmail,
      password,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true
      jwt.login({
        email: this.userEmail,
        password: this.password,
      })
        .then(response => {
          jwt.setToken(response.data.token)
          store.commit('session/updateUser', new User(response.data.user))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully logged in',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            let path = '/'
            switch (store.getters['session/user'].role.get()) {
              case RoleTypes.Admin.get():
                path = '/dashboard'
                break
              case RoleTypes.Customer.get():
                path = '/orders'
                break
              case RoleTypes.Worker.get():
                path = '/fulfillment'
                break
              default:
                break
            }

            window.location.href = path
          })
        })
        .catch(error => {
          this.submitting = false
          const { response } = error
          if (response && response.status === 403) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Invalid credentials please try again',
                icon: 'XOctagonIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong please try again later',
                icon: 'XOctagonIcon',
                variant: 'danger',
              },
            })
          }
          console.log(error)
        })
    },
    navigateToForgotPassword() {
      validate(this.userEmail, 'required|email', { name: 'Email' }).then(results => {
        if (results.valid) {
          const params = { email: this.userEmail }
          jwt.forgotPasswordInitialize(params).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Your request has been received. Please check your email for a link to reset your password',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'There was an error when processing your request. Please try again later.',
                icon: 'XOctagonIcon',
                variant: 'danger',
              },
            })
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: results.errors[0],
              icon: 'XOctagonIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
